import LuxLoyaltyTierLabel from 'components/Common/Labels/LuxLoyaltyTierLabel'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import SolidStarIcon from 'components/Luxkit/Icons/solid/SolidStarIcon'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Colour from 'components/utils/Colour'
import Plural from 'components/utils/Formatters/Plural'
import Group from 'components/utils/Group'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { useAppSelector } from 'hooks/reduxHooks'
import getLuxLoyaltyEligibleTier from 'luxLoyalty/selectors/getLuxLoyaltyEligibleTier'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import { rem } from 'polished'
import React, { ComponentProps, useCallback } from 'react'
import RealisticConfetti from 'react-canvas-confetti/dist/presets/realistic'
import styled from 'styled-components'
import LuxLoyaltyProductLogo from './LuxLoyaltyProductLogo'

const Hero = styled(Pane)`
  padding: ${rem(32)} ${rem(24)};

  &.tier-bronze {
    background: ${props => props.theme.palette.product.luxLoyalty.bronze.gradient};
  }
  &.tier-silver {
    background: ${props => props.theme.palette.product.luxLoyalty.silver.gradient};
  }
  &.tier-gold {
    background: ${props => props.theme.palette.product.luxLoyalty.gold.gradient};
  }
  &.tier-platinum {
    background: ${props => props.theme.palette.product.luxLoyalty.platinum.gradient};
  }
`

const benefits: Record<
  Exclude<App.LuxLoyaltyTier, 'platinum'>,
  Array<string>
> = {
  bronze: [
    'Gain Status Credits with every booking from day one',
    'Earn and use points across all products',
    'Double dip points with other loyalty programs',
  ],
  silver: [
    'Earn and use points across all products',
    '2x free lounge passes at select airports',
    'Double dip points with other loyalty programs',
    'Save 5% on Travel & Cancellation Protection',
  ],
  gold: [
    'Earn and use points across all products',
    '1x free room upgrade at select hotels',
    '2x free lounge passes at select airports',
    '2x free airport chauffeur service',
    'Save 15% on Travel & Cancellation Protection',
    '24/7 priority phone support',
  ],
}

const CONFETTI_COLOURS: Record<App.LuxLoyaltyTier, Array<string>> = {
  bronze: ['#EFE7E3', '#D4B5A4', '#91674E'],
  silver: ['#E7E8EA', '#BABCC2', '#6D717C'],
  gold: ['#EFE8D1', '#C6BC92', '#78704E'],
  platinum: [],
}

const CONFETTI_AUTO_RUN: NonNullable<ComponentProps<typeof RealisticConfetti>['autorun']> = { duration: 5, delay: 1, speed: 1 }

interface Props {
  onAfterClose: React.ComponentProps<typeof ModalBase>['onAfterClose']
  primaryActionProps: React.ComponentProps<typeof ModalFooter>['primaryActionProps']
  secondaryActionProps?: React.ComponentProps<typeof ModalFooter>['secondaryActionProps']
}

function LuxLoyaltyJoinModal(props: Props) {
  const {
    onAfterClose,
    primaryActionProps,
    secondaryActionProps,
  } = props

  const accountEligibleTier = useAppSelector(getLuxLoyaltyEligibleTier)
  const givenName = useAppSelector((state: App.State) => state.auth.account.givenName)
  const tierName = getLuxLoyaltyTierDisplayName(accountEligibleTier)

  const confettiColours = CONFETTI_COLOURS[accountEligibleTier]
  const confettiDecoration = useCallback<NonNullable<ComponentProps<typeof RealisticConfetti>['decorateOptions']>>((options) => {
    return {
      ...options,
      colors: confettiColours,
      origin: { y: 0.45, x: 0.5 },
      zIndex: 999,
    }
  }, [confettiColours])

  // Users will only be eligible for bronze, silver, or gold at launch
  if (accountEligibleTier === 'platinum') return null

  return <ProductPaletteProvider palette={`tier-${accountEligibleTier}`}>
    <ModalBase dismissible onAfterClose={onAfterClose}>
      <ModalBody>
        <Hero className={`tier-${accountEligibleTier}`}>
          <Group direction="vertical" gap={16}>
            <Group direction="vertical" gap={12}>
              <Group direction="horizontal" gap={12} verticalAlign="center">
                <LuxLoyaltyProductLogo height={24} />
                <LuxLoyaltyTierLabel kind="primary" tier={accountEligibleTier} format="uppercase">Exclusive Invite</LuxLoyaltyTierLabel>
              </Group>
              <BodyText variant="small">The ultimate rewards program to elevate every escape</BodyText>
            </Group>
            <div>
              <Heading variant="heading2">
                <i>{givenName}</i>, you've unlocked
                <br />
                <Colour value={`tier-${accountEligibleTier}-light-contrast`}>
                  {tierName} status + <Plural singular="bonus point" count={1_000} withCount />
                </Colour>
              </Heading>
            </div>
          </Group>
        </Hero>
        <ModalContent>
          <VerticalSpacer gap={12}>
            <BodyText variant="large" weight="bold" colour="neutral-two">
              Enjoy instant benefits as a {tierName} member
            </BodyText>
            <VerticalSpacer gap={4}>
              <BodyTextBlock variant="medium" startIcon={<SolidStarIcon />} colour="highlight-secondary" weight="bold">
                <Plural singular="bonus point" count={1_000} withCount /> - limited time only!
              </BodyTextBlock>
              {benefits[accountEligibleTier].map((benefit) => <BodyTextBlock
                key={benefit}
                variant="medium"
                startIcon={<LineCheckIcon />}
              >
                {benefit}
              </BodyTextBlock>)}
            </VerticalSpacer>
          </VerticalSpacer>
        </ModalContent>
      </ModalBody>
      <RealisticConfetti autorun={CONFETTI_AUTO_RUN} decorateOptions={confettiDecoration} />
      <ModalFooter
        primaryActionProps={primaryActionProps}
        secondaryActionProps={secondaryActionProps}
      />
    </ModalBase>
  </ProductPaletteProvider>
}

export default LuxLoyaltyJoinModal
